export default [
  {
    header: 'Settings',
    icon: 'SettingsIcon',
    i18n: 'Settings',
    items: [
      {
        url: '/settings/general',
        name: 'General',
        slug: 'general',
        icon: 'SettingsIcon',
        i18n: 'General'
      },
      {
        url: '/settings/users',
        name: 'Users',
        slug: 'users',
        icon: 'UserIcon',
        i18n: 'Users'
      }
    ]
  },
  {
    header: 'Content',
    icon: 'SettingsIcon',
    i18n: 'Content',
    items: [
      {
        url: '/content/home',
        name: 'Home',
        slug: 'home',
        icon: 'HomeIcon',
        i18n: 'Home'
      },
      {
        url: '/content/services',
        name: 'Services',
        slug: 'services',
        icon: 'ZapIcon',
        i18n: 'Services'
      },
      {
        url: '/content/clients',
        name: 'Clients',
        slug: 'clients',
        icon: 'StarIcon',
        i18n: 'Clients'
      },
      {
        url: '/content/posts',
        name: 'Posts',
        slug: 'posts',
        icon: 'FileTextIcon',
        i18n: 'Posts'
      }
    ]
  },

]

